@import '../node_modules/sass-material-colors/sass/sass-material-colors';
@import '../node_modules/normalize-opentype.css/normalize-opentype.scss';
@import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

$font-family-base: 'Lora';
$font-family-sans-serif: 'Lato';
$body-color: material-color('grey', '900');

@import '../node_modules/bootstrap/scss/bootstrap-reboot.scss';

@page {
  margin: 1.5cm 1cm;
  size: A4 portrait;
}

@-ms-viewport{
  width: device-width;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-sans-serif;
  font-feature-settings: "kern" 1, "liga" 1, "calt" 1, "pnum" 1, "tnum" 0, "onum" 0, "lnum" 0, "dlig" 0;
}
h1, h2 {
  font-weight: 900;
}

a:link {
  color: material-color('blue', '600');
  @media (prefers-color-scheme: dark) {
    color: material-color('blue', '300');
  }
}
a:visited {
  color: material-color('blue', '700');
  @media (prefers-color-scheme: dark) {
    color: material-color('blue', '200');
  }
}

body {
  border-top: 4px solid material-color('teal', '600');
  padding-top: 1em;
  @media only screen and (min-width: 1200px) {
    padding-top: 2em;
  }
  @media only screen and (max-width: 1199px) {
    font-size: 1.1rem;
    line-height: 1.6;
  }

  @media (prefers-color-scheme: dark) {
    background-color: $body-color;
    color: material-color('grey', '100');
  }
}

header {
  margin-bottom: 3em;

  @media print {
    & {
      margin-bottom: 1em;
    }
  }
}

#container {
  margin: 0 10px;

  @media only screen and (min-width: 1200px) {
    width: 1200px;
    margin: 0 auto;
  }
}

@media screen {
  .only-print {
    display: none;
  }
}

@media print {
  .only-screen {
    display: none;
  }

  html {
    font-size: 14px;
  }

  body {
    margin: 0;
    padding: 0;
    border: 0;
    color: black;
    background-color: white;
  }

  #container {
    width: auto;
    margin: 0;
  }

  a:link, a:visited {
    color: black;
  }

}

@import "section";
@import "skill";
@import "intro";
@import "time-span";