.section {
  // outline: 1px solid red;
  margin-bottom: 3em;

  @media print {
    margin-bottom: 1em;
    page-break-inside: avoid;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  h2 {
    margin-bottom: 1em;
    font-weight: 900;

    @media print {
      & {
        margin-bottom: 0.2em;
      }
    }
  }
}

.section--basics, .section--work, .section--education, .section--skills, .section--references {
  @extend .section !optional;
}

.section--basics {
  .section--basics__email, .section--basics__phone, .section--basics__website {
    dt {
      display: inline-block;
      width: 5em;
    }
    dd {
      display: inline;
    }
  }
  .section--basics__social {
    dt {
      display: inline-block;
    }
    dd {
      display: inline-block;
      border-radius: 4px;
      margin: 2px 5px;
      padding: 2px 6px;
      font-size: 0.9em;
      white-space: nowrap;
      border: 1px solid material-color('grey', '200');
      background: material-color('grey', '100');
      color: $body_color;
      @media (prefers-color-scheme: dark) {
        border: 1px solid material-color('grey', '800');
        background: black;
        color: material-color('grey', '100');
      }
      @media print {
        border: 1px solid material-color('grey', '200');
        background: material-color('grey', '100');
        color: $body_color;
      }
    }
  }
}

.section--work{

  @media print {
    page-break-inside: initial;
  }
}

.section--references {
  font-style: italic;
}

.job, .school {
  margin-bottom: 2em;

  @media print {
    page-break-inside: avoid;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@media print {
  .job__company {
    font-weight: bold;
  }

  .job__company, .job__website, .job__service {
    display: inline-block;
    margin-right: 1.2em;
  }
}
