@mixin skill($material-color) {

  dt {
    display: none;
  }

  dd {
    display: block;
    float: left;
    border-radius: 4px;
    border: 1px solid material-color($material-color, '500');
    background: material-color($material-color, '400');
    color: material-color('grey', '100');
    @media (prefers-color-scheme: dark) {
      border: 1px solid material-color($material-color, '400');
      background: material-color($material-color, '500');
    }
    margin: 4px 10px 4px 0;
    padding: 0 6px;
    font-size: 0.9em;
  }

  @media print {
    & {
      margin: 0;
    }
    & dd {
      background: none !important;
      border: 0 !important;
      color: black !important;
      margin: 0;
      padding: 0 0.5em 0 0;

      &::after {
        content: ',';
      }
    }

    &:last-child dd:last-child::after {
      content: '';
    }
  }
}

.skill--languages {
  @include skill('pink')
}

.skill--backend-php {
  @include skill('purple')
}

.skill--backend-other {
  @include skill('deep-purple')
}

.skill--frontend-style {
  @include skill('indigo')
}

.skill--frontend-js {
  @include skill('light-blue')
}

.skill--operating-systems {
  @include skill('cyan')
}

.skill--software {
  @include skill('teal')
}

.skill--devops {
  @include skill('green')
}